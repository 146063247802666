import DashboardSecretario from 'views/secretario/DashboardSecretario';
import Noticias from 'views/gestor/Noticias';
import Noticia from 'views/gestor/Noticia';
import Documentos from 'views/gestor/Documentos';
import RelatoriosEstatisticos from 'views/gestor/RelatoriosEstatisticos';
import ImprimirRelatorioAlunosBaixaFrequenciaPorTurma from 'views/gestor/ImprimirRelatorioAlunosBaixaFrequenciaPorTurma';
import ImprimirRelatorioDatasAnoLetivo from 'views/gestor/ImprimirRelatorioDatasAnoLetivo';

import RelatoriosEscolasTurmas from 'views/gestor/RelatoriosEscolasTurmas';
import RelatoriosTransportes from 'views/gestor/RelatoriosTransportes';
import ImprimirRelatorioTurmasPorEscola from 'views/gestor/ImprimirRelatorioTurmasPorEscola';
import ImprimirRelatorioTurmasPorSerie from 'views/gestor/ImprimirRelatorioTurmasPorSerie';
import ImprimirListagemRelatoriosTurmasNaoInformadosPorEscola from 'views/gestor/ImprimirListagemRelatoriosTurmasNaoInformadosPorEscola';
import ImprimirRelatorioDiariosPorEscola from 'views/gestor/ImprimirRelatorioDiariosPorEscola';
import ImprimirRelatorioFuncionariosPorEscola from 'views/gestor/ImprimirRelatorioFuncionariosPorEscola';
import ImprimirRelatorioOcorrenciasPorEscola from 'views/gestor/ImprimirRelatorioOcorrenciasPorEscola';
import ImprimirRelatorioJustificativasFaltasPorTurma from 'views/gestor/ImprimirRelatorioJustificativasFaltasPorTurma';
import ImprimirRelatorioLivroMatriculaPorEscola from 'views/gestor/ImprimirRelatorioLivroMatriculaPorEscola';
import ImprimirRelatorioLivroMatriculaPorTurma from 'views/gestor/ImprimirRelatorioLivroMatriculaPorTurma';
import ImprimirRelatorioNotasFinaisPorTurma from 'views/gestor/ImprimirRelatorioNotasFinaisPorTurma';
import ImprimirRelatorioFichasIndividuaisPorTurma from 'views/gestor/ImprimirRelatorioFichasIndividuaisPorTurma';
import ImprimirRelatorioFrequenciasMensaisPorTurma from 'views/gestor/ImprimirRelatorioFrequenciasMensaisPorTurma';
import ImprimirRelatorioQuantidadeFrequenciaDia from 'views/gestor/ImprimirRelatorioQuantidadeFrequenciaDia';

import RelatoriosDistorcaoIdadeSerie from 'views/gestor/RelatoriosDistorcaoIdadeSerie';
import ImprimirRelatorioDistorcaoIdadeSeriePorEscola from 'views/gestor/ImprimirRelatorioDistorcaoIdadeSeriePorEscola';

import RelatoriosProfessores from 'views/gestor/RelatoriosProfessores';
import ImprimirRelatorioProfessoresPorTurma from 'views/gestor/ImprimirRelatorioProfessoresPorTurma';
import ImprimirRelatorioProfessoresPorEscola from 'views/gestor/ImprimirRelatorioProfessoresPorEscola';

import RelatoriosAlunos from 'views/gestor/RelatoriosAlunos';
import ImprimirRelatorioAlunosPorEscola from 'views/gestor/ImprimirRelatorioAlunosPorEscola';
import ImprimirRelatorioAlunosBolsaFamiliaPorEscola from 'views/gestor/ImprimirRelatorioAlunosBolsaFamiliaPorEscola';
import ImprimirRelatorioAlunosPorTurma from 'views/gestor/ImprimirRelatorioAlunosPorTurma';
import ImprimirRelatorioAlunosPnePorEscola from 'views/gestor/ImprimirRelatorioAlunosPnePorEscola';
import ImprimirRelatorioAlunosPnePorTurma from 'views/gestor/ImprimirRelatorioAlunosPnePorTurma';
import ImprimirRelatorioAlunosPorIdade from 'views/gestor/ImprimirRelatorioAlunosPorIdade';
import ImprimirRelatorioAlunosForaDeFaixaPorEscola from 'views/gestor/ImprimirRelatorioAlunosForaDeFaixaPorEscola';
import ImprimirRelatorioAlunosAbaixoDaMediaPorTurma from 'views/gestor/ImprimirRelatorioAlunosAbaixoDaMediaPorTurma';
//import ImprimirRelatorioAlunosBaixaFrequenciaPorTurma from 'views/gestor/ImprimirRelatorioAlunosBaixaFrequenciaPorTurma';
import ImprimirRelatorioHabilidadesAdquiridasAluno from 'views/gestor/ImprimirRelatorioHabilidadesAdquiridasAluno';
import ImprimirRelatorioHabilidadesAdquiridas from 'views/gestor/ImprimirRelatorioHabilidadesAdquiridas';
import ImprimirRelatorioNotasPorTurma from 'views/gestor/ImprimirRelatorioNotasPorTurma';
import ImprimirRelatorioMediasAlunosPorEscola from 'views/gestor/ImprimirRelatorioMediasAlunosPorEscola';
import ImprimirRelatorioNotasAlunosPorMateria from 'views/gestor/ImprimirRelatorioNotasAlunosPorMateria';
import ImprimirRelatorioNotasAlunosPorProfessor from 'views/gestor/ImprimirRelatorioNotasAlunosPorProfessor';
import ImprimirRelatorioNotasAlunosPorBimestre from 'views/gestor/ImprimirRelatorioNotasAlunosPorBimestre';
import ImprimirRelatorioFrequenciaAlunosPorMes from 'views/gestor/ImprimirRelatorioFrequenciaAlunosPorMes';
import ImprimirRelatorioFrequenciaAlunosPorBimestre from 'views/gestor/ImprimirRelatorioFrequenciaAlunosPorBimestre';
import ImprimirRelatorioFrequenciaAlunosPorMateria from 'views/gestor/ImprimirRelatorioFrequenciaAlunosPorMateria';
import ImprimirRelatorioContagemAlunos from 'views/gestor/ImprimirRelatorioContagemAlunos';
import ImprimirRelatorioContagemAlunosPorEscolaEtapa from 'views/gestor/ImprimirRelatorioContagemAlunosPorEscolaEtapa';
import ImprimirRelatorioContagemAlunosAprovadosReprovadosPorEscola from 'views/gestor/ImprimirRelatorioContagemAlunosAprovadosReprovadosPorEscola';
import ImprimirRelatorioFaltasAluno from 'views/gestor/ImprimirRelatorioFaltasAluno';
import ImprimirRelatorioPeiAluno from 'views/gestor/ImprimirRelatorioPeiAluno';
import ImprimirRelatorioAlunosPorTurnoPorEscola from 'views/gestor/ImprimirRelatorioAlunosPorTurnoPorEscola';
import ImprimirRelatorioAlunosMatriculadosDependenciaPorTurma from 'views/gestor/ImprimirRelatorioAlunosMatriculadosDependenciaPorTurma';
import ImprimirRelatorioAlunosAprovadosDependenciaPorTurma from 'views/gestor/ImprimirRelatorioAlunosAprovadosDependenciaPorTurma';

import RelatoriosRegistrosAula from 'views/gestor/RelatoriosRegistrosAula';
import ImprimirRelatorioRegistrosAulaPorEscola from 'views/gestor/ImprimirRelatorioRegistrosAulaPorEscola';
import ImprimirRelatorioRegistrosAulaPorTurma from 'views/gestor/ImprimirRelatorioRegistrosAulaPorTurma';
import ImprimirRelatorioRegistrosAulaPorProfessor from 'views/gestor/ImprimirRelatorioRegistrosAulaPorProfessor';
import ImprimirRelatorioQuantidadeRegistrosAulaPorEscola from 'views/gestor/ImprimirRelatorioQuantidadeRegistrosAulaPorEscola';
import ImprimirRelatorioQuantidadeRegistrosAulaPorTurma from 'views/gestor/ImprimirRelatorioQuantidadeRegistrosAulaPorTurma';
import ImprimirRelatorioQuantidadeRegistrosAulaPorProfessor from 'views/gestor/ImprimirRelatorioQuantidadeRegistrosAulaPorProfessor';
import ImprimirRelatorioDiariosRegistroAulasEmAtraso from 'views/gestor/ImprimirRelatorioDiariosRegistroAulasEmAtraso';

import Escolas from 'views/gestor/Escolas';
import Escola from 'views/gestor/Escola';
import CadastroSolicitacaoTransferencia from 'views/gestor/CadastroSolicitacaoTransferencia';
import ImprimirHistorico from 'views/gestor/ImprimirHistorico';
import ImprimirHistoricoResumido from 'views/gestor/ImprimirHistoricoResumido';
import ImprimirDeclaracaoMatricula from 'views/gestor/ImprimirDeclaracaoMatricula';
import ImprimirDeclaracaoConclusao from 'views/gestor/ImprimirDeclaracaoConclusao';
import ImprimirDeclaracaoConclusaoSerie from 'views/gestor/ImprimirDeclaracaoConclusaoSerie';
import ImprimirDeclaracaoFrequenciaMensal from 'views/gestor/ImprimirDeclaracaoFrequenciaMensal';
import ImprimirDeclaracaoTransferencia from 'views/gestor/ImprimirDeclaracaoTransferencia';
import ImprimirDeclaracaoGeral from 'views/gestor/ImprimirDeclaracaoGeral';
import ImprimirBoletimAluno from 'views/gestor/ImprimirBoletimAluno';
import ImprimirFichaMatricula from 'views/gestor/ImprimirFichaMatricula';
import ImprimirFichaIndividual from 'views/gestor/ImprimirFichaIndividual';
import ImprimirFichasIndividuaisPorTurma from 'views/gestor/ImprimirFichasIndividuaisPorTurma';
import ImprimirDeclaracaoVagasEscola from 'views/gestor/ImprimirDeclaracaoVagasEscola';
import CadastroItemMerenda from 'views/gestor/CadastroItemMerenda';
import CadastroFuncionario from 'views/gestor/CadastroFuncionario';
import Funcionario from 'views/gestor/Funcionario';
import Alunos from 'views/gestor/Alunos';
import Pais from 'views/gestor/Pais';
import EducaCenso from 'views/gestor/EducaCenso';
import PreCadastroAluno from 'views/gestor/PreCadastroAluno';
import CadastroAluno from 'views/gestor/CadastroAluno';
import HistoricoAluno from 'views/gestor/HistoricoAluno';
import AnosLetivos from 'views/gestor/AnosLetivos';
import AnoLetivo from 'views/gestor/AnoLetivo';
import Convites from 'views/gestor/ConvitesDiretoresProfessores';
import TurmasEscola from 'views/gestor/TurmasEscola';
import CadastroTurma from 'views/gestor/CadastroTurma';
import CadastroTurmaMultiseriado from 'views/gestor/CadastroTurmaMultiseriado';
import Turma from 'views/gestor/Turma';
import TurmaMultiseriado from 'views/gestor/TurmaMultiseriado';
import MatriculaAluno from 'views/gestor/MatriculaAluno';
import MudarSituacaoAluno from 'views/gestor/MudarSituacaoAluno';
import FrequenciaDiario from 'views/gestor/FrequenciaDiario';
import CadastroHistorico from 'views/gestor/CadastroHistorico';
import Depoimentos from 'views/comum/Depoimentos';
import CadastroDepoimento from 'views/comum/CadastroDepoimento';
import RelatorioContagemGeral from 'views/gestor/RelatorioContagemGeral';
import RelatorioSituacaoTurmas from 'views/gestor/RelatorioSituacaoTurmas';
import RelatorioSituacaoDiarios from 'views/gestor/RelatorioSituacaoDiarios';
import RelatorioInformacoesMatriculas from 'views/gestor/RelatorioInformacoesMatriculas';
import RelatorioMatriculasPorSerie from 'views/gestor/RelatorioMatriculasPorSerie';
import RelatorioTurmasPorSerie from 'views/gestor/RelatorioTurmasPorSerie';
import RelatorioDistorcaoIdadeSerie from 'views/gestor/RelatorioDistorcaoIdadeSerie';
import RelatorioMatriculasPorSexo from 'views/gestor/RelatorioMatriculasPorSexo';
import RelatorioMatriculasPorEtnia from 'views/gestor/RelatorioMatriculasPorEtnia';
import RelatorioTaxaEvasao from 'views/gestor/RelatorioTaxaEvasao';
import CadastroOcorrencia from 'views/gestor/CadastroOcorrencia';
import Ocorrencia from 'views/gestor/Ocorrencia';
import Ouvidoria from 'views/gestor/Ouvidoria';
import CadastroChamadoOuvidoria from 'views/gestor/CadastroChamadoOuvidoria';
import ChamadoOuvidoria from 'views/gestor/ChamadoOuvidoria';

import GerenciaDiarios from 'views/gestor/GerenciaDiarios';
import Diario from 'views/professor/Diario';
import DiarioAlfabetizacao from 'views/professor/DiarioAlfabetizacao';
import DiarioTurmaMultiseriado from 'views/professor/DiarioTurmaMultiseriado';
import Notas from 'views/professor/Notas';
import Relatorios from 'views/professor/Relatorios';
import RelatorioAluno from 'views/professor/RelatorioAluno';
import AvaliacaoEspecialAluno from 'views/professor/AvaliacaoEspecialAluno';
import AvaliacaoConceitual from 'views/professor/AvaliacaoConceitual';
import ConteudoLecionado from 'views/professor/ConteudoLecionado';
import ConteudoLecionadoTurmaMultiseriado from 'views/professor/ConteudoLecionadoTurmaMultiseriado';
import HabilidadesTrabalhadas from 'views/professor/HabilidadesTrabalhadas';
import HabilidadesAdquiridasAluno from 'views/professor/HabilidadesAdquiridasAluno';
import HabilidadesAdquiridasAlunoBimestres from 'views/professor/HabilidadesAdquiridasAlunoBimestres';
import CompetenciasMunicipaisAdquiridasAluno from 'views/professor/CompetenciasMunicipaisAdquiridasAluno';
import ObjetivosAprendizagemTrabalhados from 'views/professor/ObjetivosAprendizagemTrabalhados';
import ObjetivosAprendizagemAdquiridosAluno from 'views/professor/ObjetivosAprendizagemAdquiridosAluno';
import ObjetivosAprendizagemOuHabilidadesTrabalhadasMultiseriado from 'views/professor/ObjetivosAprendizagemOuHabilidadesTrabalhadasMultiseriado';
import ObjetivosAprendizagemOuHabilidadesAdquiridasAlunoMultiseriado from 'views/professor/ObjetivosAprendizagemOuHabilidadesAdquiridasAlunoMultiseriado';
import RelatorioTurma from 'views/professor/RelatorioTurma';
import RelatorioTurmaMultiseriado from 'views/professor/RelatorioTurmaMultiseriado';
import CalendarioFrequencia from 'views/professor/CalendarioFrequencia';
import CalendarioFrequenciaMultiseriado from 'views/professor/CalendarioFrequenciaMultiseriado';
import MesFrequencia from 'views/professor/MesFrequencia';
import MesFrequenciaMultiseriado from 'views/professor/MesFrequenciaMultiseriado';
import JustificativaFaltas from 'views/professor/JustificativaFaltas';
import JustificativaFaltasMultiseriado from 'views/professor/JustificativaFaltasMultiseriado';
import Abono from 'views/professor/Abono';
import AbonoMultiseriado from 'views/professor/AbonoMultiseriado';
import Frequencia from 'views/professor/Frequencia';
import FrequenciaMultiseriado from 'views/professor/FrequenciaMultiseriado';
import TurmaVirtual from 'views/professor/TurmaVirtual';
import Aula from 'views/professor/Aula';
import Tarefa from 'views/professor/Tarefa';
import EnviosAluno from 'views/professor/EnviosAluno';
import AtaFinal from 'views/professor/AtaFinal';
import AtaFinalAlfabetizacao from 'views/professor/AtaFinalAlfabetizacao';
import AtaFinalTurmaMultiseriado from 'views/professor/AtaFinalTurmaMultiseriado';
import RegistroAulas from 'views/professor/RegistroAulas';
import RegistroAulasMultiseriado from 'views/professor/RegistroAulasMultiseriado';
import RegistroAula from 'views/professor/RegistroAula';
import MatriculasOnline from 'views/gestor/MatriculasOnline';
import SolicitacaoMatricula from 'views/gestor/SolicitacaoMatricula';
import AulasProfessores from 'views/gestor/AulasProfessores';
import ListagemAulasProfessor from 'views/gestor/ListagemAulasProfessor';
import AulaProfessor from 'views/gestor/AulaProfessor';
import Tutoriais from 'views/gestor/Tutoriais';

import Estoque from 'views/gestor/Estoque';
import CadastroEstoqueItem from 'views/gestor/CadastroEstoqueItem';
import EstoqueItem from 'views/gestor/EstoqueItem';
import CadastroEstoqueEntrada from 'views/gestor/CadastroEstoqueEntrada';
import EstoqueEntrada from 'views/gestor/EstoqueEntrada';
import CadastroEstoqueSaida from 'views/gestor/CadastroEstoqueSaida';
import EstoqueSaida from 'views/gestor/EstoqueSaida';

import DadosUsuario from 'views/comum/DadosUsuario';
import MudarSenha from 'views/comum/MudarSenha';

import Papel from 'assets/csv/papeis.json';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fa fa-line-chart',
    component: DashboardSecretario,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/noticias',
    name: 'Notícias',
    icon: 'far fa-newspaper',
    component: Noticias,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/noticias/:idNoticia',
    component: Noticia,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/documentos',
    name: 'Documentos',
    icon: 'fas fa-file-pdf',
    component: Documentos,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/documentos/historicos',
    component: ImprimirHistorico,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/documentos/historicos/resumidos',
    component: ImprimirHistoricoResumido,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/documentos/declaracao/matricula',
    component: ImprimirDeclaracaoMatricula,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/documentos/declaracao/conclusao',
    component: ImprimirDeclaracaoConclusao,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/documentos/declaracao/conclusao/serie',
    component: ImprimirDeclaracaoConclusaoSerie,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/documentos/declaracao/frequencia/mensal',
    component: ImprimirDeclaracaoFrequenciaMensal,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/documentos/declaracao/transferencia',
    component: ImprimirDeclaracaoTransferencia,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/documentos/declaracao/geral',
    component: ImprimirDeclaracaoGeral,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/documentos/boletim/aluno',
    component: ImprimirBoletimAluno,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/documentos/ficha/matricula',
    component: ImprimirFichaMatricula,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/documentos/ficha/individual',
    component: ImprimirFichaIndividual,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/documentos/fichas/individuais/turma',
    component: ImprimirFichasIndividuaisPorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios',
    name: 'Relatórios',
    icon: 'fas fa-file-pdf',
    component: RelatoriosEstatisticos,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunosbaixafrequenciaporturma',
    component: ImprimirRelatorioAlunosBaixaFrequenciaPorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/datasanoletivo',
    component: ImprimirRelatorioDatasAnoLetivo,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/escolasturmas',
    component: RelatoriosEscolasTurmas,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/transportes',
    component: RelatoriosTransportes,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/escolasturmas/turmasporescola',
    component: ImprimirRelatorioTurmasPorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/escolasturmas/listagemrelatoriosturmasnaoinformadosporescola',
    component: ImprimirListagemRelatoriosTurmasNaoInformadosPorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/escolasturmas/turmasporserie',
    component: ImprimirRelatorioTurmasPorSerie,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/escolasturmas/diariosporescola',
    component: ImprimirRelatorioDiariosPorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/escolasturmas/funcionariosporescola',
    component: ImprimirRelatorioFuncionariosPorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/escolasturmas/ocorrenciasporescola',
    component: ImprimirRelatorioOcorrenciasPorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/escolasturmas/justificativasfaltasporturma',
    component: ImprimirRelatorioJustificativasFaltasPorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/escolasturmas/livromatriculaporescola',
    component: ImprimirRelatorioLivroMatriculaPorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/escolasturmas/livromatriculaporturma',
    component: ImprimirRelatorioLivroMatriculaPorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/escolasturmas/notasfinaisporturma',
    component: ImprimirRelatorioNotasFinaisPorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/escolasturmas/fichasindividuaisporturma',
    component: ImprimirRelatorioFichasIndividuaisPorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/escolasturmas/frequenciasmensaisporturma',
    component: ImprimirRelatorioFrequenciasMensaisPorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/escolasturmas/quantidadefrequenciadia',
    component: ImprimirRelatorioQuantidadeFrequenciaDia,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/distorcaoidadeserie',
    component: RelatoriosDistorcaoIdadeSerie,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/distorcaoidadeserie/porescola',
    component: ImprimirRelatorioDistorcaoIdadeSeriePorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/professores',
    component: RelatoriosProfessores,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/professores/porturma',
    component: ImprimirRelatorioProfessoresPorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/professores/porescola',
    component: ImprimirRelatorioProfessoresPorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos',
    component: RelatoriosAlunos,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/porescola',
    component: ImprimirRelatorioAlunosPorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunosbolsafamilia/porescola',
    component: ImprimirRelatorioAlunosBolsaFamiliaPorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/porturma',
    component: ImprimirRelatorioAlunosPorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/pneporescola',
    component: ImprimirRelatorioAlunosPnePorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/pneporturma',
    component: ImprimirRelatorioAlunosPnePorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/poridade',
    component: ImprimirRelatorioAlunosPorIdade,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/foradefaixaporescola',
    component: ImprimirRelatorioAlunosForaDeFaixaPorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/abaixodamediaporturma',
    component: ImprimirRelatorioAlunosAbaixoDaMediaPorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/habilidadesadquiridasporaluno',
    component: ImprimirRelatorioHabilidadesAdquiridasAluno,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/habilidades/matricula/:idMatricula/bimestres',
    component: HabilidadesAdquiridasAlunoBimestres,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/habilidadesadquiridasporturma',
    component: ImprimirRelatorioHabilidadesAdquiridas,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/notasporturma',
    component: ImprimirRelatorioNotasPorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/mediasporescola',
    component: ImprimirRelatorioMediasAlunosPorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/notaspormateria',
    component: ImprimirRelatorioNotasAlunosPorMateria,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/notasporprofessor',
    component: ImprimirRelatorioNotasAlunosPorProfessor,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/notasporbimestre',
    component: ImprimirRelatorioNotasAlunosPorBimestre,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/frequenciapormes',
    component: ImprimirRelatorioFrequenciaAlunosPorMes,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/frequenciaporbimestre',
    component: ImprimirRelatorioFrequenciaAlunosPorBimestre,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/frequenciapormateria',
    component: ImprimirRelatorioFrequenciaAlunosPorMateria,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/contagemporescola',
    component: ImprimirRelatorioContagemAlunos,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/contagemporescolaetapa',
    component: ImprimirRelatorioContagemAlunosPorEscolaEtapa,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/percentualaprovadoreprovadoporescola',
    component: ImprimirRelatorioContagemAlunosAprovadosReprovadosPorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/faltas',
    component: ImprimirRelatorioFaltasAluno,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/pei',
    component: ImprimirRelatorioPeiAluno,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/turno',
    component: ImprimirRelatorioAlunosPorTurnoPorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/matriculados/dependencia',
    component: ImprimirRelatorioAlunosMatriculadosDependenciaPorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/alunos/aprovados/dependencia',
    component: ImprimirRelatorioAlunosAprovadosDependenciaPorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/registrosaula',
    component: RelatoriosRegistrosAula,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/registrosaula/porescola',
    component: ImprimirRelatorioRegistrosAulaPorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/registrosaula/porturma',
    component: ImprimirRelatorioRegistrosAulaPorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/registrosaula/porprofessor',
    component: ImprimirRelatorioRegistrosAulaPorProfessor,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/quantidaderegistrosaula/porescola',
    component: ImprimirRelatorioQuantidadeRegistrosAulaPorEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/quantidaderegistrosaula/porturma',
    component: ImprimirRelatorioQuantidadeRegistrosAulaPorTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/quantidaderegistrosaula/porprofessor',
    component: ImprimirRelatorioQuantidadeRegistrosAulaPorProfessor,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/relatorios/diariosregistroaulasematraso/porescola',
    component: ImprimirRelatorioDiariosRegistroAulasEmAtraso,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas',
    name: 'Escolas',
    icon: 'fas fa-school',
    component: Escolas,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola',
    component: Escola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/solicitar/transferencia',
    component: CadastroSolicitacaoTransferencia,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/historicos',
    component: ImprimirHistorico,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/declaracao/matricula',
    component: ImprimirDeclaracaoMatricula,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/declaracao/frequencia/mensal',
    component: ImprimirDeclaracaoFrequenciaMensal,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/declaracao/transferencia',
    component: ImprimirDeclaracaoTransferencia,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/boletim/aluno',
    component: ImprimirBoletimAluno,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/ficha/matricula',
    component: ImprimirFichaMatricula,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/ficha/individual',
    component: ImprimirFichaIndividual,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/documentos/declaracao/vagas',
    component: ImprimirDeclaracaoVagasEscola,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/itemmerenda/cadastro',
    component: CadastroItemMerenda,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/itemmerenda/edicao/:idItemMerenda',
    component: CadastroItemMerenda,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/funcionario/cadastro',
    component: CadastroFuncionario,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/funcionario/edicao/:idFuncionario',
    component: CadastroFuncionario,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/funcionario/:idFuncionario',
    component: Funcionario,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque',
    component: Estoque,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/itens/cadastro',
    component: CadastroEstoqueItem,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/itens/edicao/:idEstoqueItem',
    component: CadastroEstoqueItem,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/itens/:idEstoqueItem',
    component: EstoqueItem,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/entradas/cadastro',
    component: CadastroEstoqueEntrada,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/entradas/edicao/:idEstoqueEntrada',
    component: CadastroEstoqueEntrada,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/entradas/:idEstoqueEntrada',
    component: EstoqueEntrada,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/saidas/cadastro',
    component: CadastroEstoqueSaida,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/saidas/edicao/:idEstoqueSaida',
    component: CadastroEstoqueSaida,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/saidas/:idEstoqueSaida',
    component: EstoqueSaida,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos',
    name: 'Anos letivos',
    icon: 'fa fa-calendar',
    component: AnosLetivos,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo',
    component: AnoLetivo,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola',
    component: TurmasEscola,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turmas/cadastro',
    component: CadastroTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turmas/edicao/:idTurma',
    component: CadastroTurma,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turmas/multiseriado/cadastro',
    component: CadastroTurmaMultiseriado,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turmas/multiseriado/edicao/:idTurma',
    component: CadastroTurmaMultiseriado,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma',
    component: Turma,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma',
    component: TurmaMultiseriado,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/matricula/aluno',
    component: MatriculaAluno,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/matricula/aluno/edicao/:idMatricula',
    component: MatriculaAluno,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/situacao/:idMatricula',
    component: MudarSituacaoAluno,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/ano',
    component: FrequenciaDiario,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/ocorrencias/cadastro',
    component: CadastroOcorrencia,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/ocorrencias/edicao/:idOcorrencia',
    component: CadastroOcorrencia,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/ocorrencia/:idOcorrencia',
    component: Ocorrencia,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/ouvidoria/chamados',
    component: Ouvidoria,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/ouvidoria/chamados/cadastrar',
    component: CadastroChamadoOuvidoria,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/ouvidoria/chamados/:idChamadoOuvidoria/editar',
    component: CadastroChamadoOuvidoria,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/ouvidoria/chamados/:idChamadoOuvidoria',
    component: ChamadoOuvidoria,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/convites',
    name: 'Convites',
    icon: 'fa fa-envelope-o',
    component: Convites,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
    acessoPais_OU_acessoAlunos: true,
  },
  {
    path: '/alunos',
    name: 'Alunos',
    icon: 'fa fa-users',
    component: Alunos,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/alunos/precadastro',
    component: PreCadastroAluno,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/alunos/cadastro',
    component: CadastroAluno,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/alunos/edicao/:idAluno',
    component: CadastroAluno,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/alunos/historico/:idAluno',
    component: HistoricoAluno,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/alunos/historico/:idAluno/cadastro',
    component: CadastroHistorico,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/alunos/historico/:idAluno/edicao/:idHistorico',
    component: CadastroHistorico,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/pais',
    name: 'Pais',
    icon: 'fa fa-user-friends',
    component: Pais,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
    acessoPais: true,
  },
  {
    path: '/pais/:idUsuario',
    component: DadosUsuario,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
    acessoPais: true,
  },
  {
    path: '/educacenso',
    name: 'Educacenso',
    icon: 'fas fa-table',
    component: EducaCenso,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
    educaCenso: true,
  },
  {
    path: '/depoimentos',
    component: Depoimentos,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/depoimentos/cadastro',
    component: CadastroDepoimento,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/depoimentos/:idDepoimento',
    component: CadastroDepoimento,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/dashboard/relatorios/contagemgeral/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioContagemGeral,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/dashboard/relatorios/situacaoturmas/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioSituacaoTurmas,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/dashboard/relatorios/situacaodiarios/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioSituacaoDiarios,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/dashboard/relatorios/informacoesmatriculas/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioInformacoesMatriculas,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/dashboard/relatorios/matriculasserie/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioMatriculasPorSerie,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/dashboard/relatorios/turmasserie/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioTurmasPorSerie,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/dashboard/relatorios/distorcaoidadeserie/anoletivo/:idAnoLetivo',
    component: RelatorioDistorcaoIdadeSerie,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/dashboard/relatorios/distorcaoidadeserie/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioDistorcaoIdadeSerie,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/dashboard/relatorios/matriculasporsexo/anoletivo/:idAnoLetivo',
    component: RelatorioMatriculasPorSexo,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/dashboard/relatorios/matriculasporsexo/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioMatriculasPorSexo,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/dashboard/relatorios/matriculasporetnia/anoletivo/:idAnoLetivo',
    component: RelatorioMatriculasPorEtnia,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/dashboard/relatorios/matriculasporetnia/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioMatriculasPorEtnia,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/dashboard/relatorios/taxaevasao/anoletivo/:idAnoLetivo',
    component: RelatorioTaxaEvasao,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/dashboard/relatorios/taxaevasao/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioTaxaEvasao,
    layout: '/secretario',
    redirect: true,
    role: Papel.SECRETARIO.value,
  },

  // diários
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/gerenciadiarios',
    component: GerenciaDiarios,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario',
    component: Diario,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao',
    component: DiarioAlfabetizacao,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario',
    component: DiarioTurmaMultiseriado,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/notas/:idMateria',
    component: Notas,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/:idDiario/notas/:idMateria',
    component: Notas,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/relatorios/turma',
    component: RelatorioTurma,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/relatorios/turma',
    component: RelatorioTurmaMultiseriado,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/relatorios/:idMateria',
    component: Relatorios,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/relatorio/aluno/:idAluno',
    component: RelatorioAluno,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/avaliacao/especial/matricula/:idMatricula',
    component: AvaliacaoEspecialAluno,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/avaliacao/conceitual/materia/:idMateria',
    component: AvaliacaoConceitual,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/conteudo/:idMateria',
    component: ConteudoLecionado,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/conteudo',
    component: ConteudoLecionadoTurmaMultiseriado,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/conteudo',
    component: ConteudoLecionado,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/habilidades',
    component: HabilidadesTrabalhadas,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/habilidades/matricula/:idMatricula',
    component: HabilidadesAdquiridasAluno,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/competencias/municipais/matricula/:idMatricula',
    component: CompetenciasMunicipaisAdquiridasAluno,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/objetivos',
    component: ObjetivosAprendizagemTrabalhados,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/objetivos/matricula/:idMatricula',
    component: ObjetivosAprendizagemAdquiridosAluno,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/objetivos/habilidades',
    component: ObjetivosAprendizagemOuHabilidadesTrabalhadasMultiseriado,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/objetivos/habilidades/matricula/:idMatricula',
    component: ObjetivosAprendizagemOuHabilidadesAdquiridasAlunoMultiseriado,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia',
    component: CalendarioFrequencia,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia',
    component: CalendarioFrequenciaMultiseriado,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/mes',
    component: MesFrequencia,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia/mes',
    component: MesFrequenciaMultiseriado,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/justificativa/faltas',
    component: JustificativaFaltas,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia/justificativa/faltas',
    component: JustificativaFaltasMultiseriado,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/abono',
    component: Abono,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/frequencia/abono',
    component: AbonoMultiseriado,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/data/:data',
    component: Frequencia,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia/data/:data',
    component: FrequenciaMultiseriado,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual',
    component: TurmaVirtual,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/aula/:idAula',
    component: Aula,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/tarefa/:idTarefa',
    component: Tarefa,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/tarefa/:idTarefa/envios/aluno/:idAluno',
    component: EnviosAluno,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/atafinal',
    component: AtaFinal,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/alfabetizacao/:idDiario/atafinal',
    component: AtaFinalAlfabetizacao,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/atafinal',
    component: AtaFinalTurmaMultiseriado,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/registroaulas',
    component: RegistroAulas,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/registroaulas',
    component: RegistroAulasMultiseriado,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/registroaulas/:idRegistroAula',
    component: RegistroAula,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/matriculasonline',
    name: 'Matrículas Online',
    icon: 'fas fa-address-card',
    component: MatriculasOnline,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
    matriculasOnline: true,
  },
  {
    path: '/matriculasonline/solicitacaomatricula/:idSolicitacaoMatricula',
    component: SolicitacaoMatricula,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/aulasprofessores',
    name: 'Aulas Virtuais',
    icon: 'fas fa-tablet-alt',
    component: AulasProfessores,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/aulasprofessores/professor/:idProfessor/listagemaulas',
    component: ListagemAulasProfessor,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/aulasprofessores/professor/:idProfessor/listagemaulas/aula/:idAula',
    component: AulaProfessor,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/tutoriais',
    component: Tutoriais,
    redirect: true,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
];

const routesProfile = [
  {
    path: '/dados',
    name: 'Meus Dados',
    mini: 'M',
    component: DadosUsuario,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
  {
    path: '/mudarsenha',
    name: 'Alterar senha',
    mini: 'A',
    component: MudarSenha,
    layout: '/secretario',
    role: Papel.SECRETARIO.value,
  },
];

export { routesProfile };
export default routes;
