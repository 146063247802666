import React from 'react';
// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Table,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

class Documentos extends React.Component {
  render = () => <div className="content">
    <Row>
      <Col md="12">
        <Breadcrumb>
          <BreadcrumbItem active>Documentos</BreadcrumbItem>
        </Breadcrumb>
      </Col>
    </Row>
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle></CardTitle>
          </CardHeader>
          <CardBody>
            <PerfectScrollbar className="sisa-perfect-scrollbar">
            <Table hover>
              <thead className="text-primary">
                <tr>
                  <th>Documentos</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/documentos/historicos`)}>
                  <td>Imprimir Histórico de Aluno</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/documentos/historicos/resumidos`)}>
                  <td>Imprimir Histórico Resumido de Aluno</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/documentos/declaracao/matricula`)}>
                  <td>Imprimir Declaração de Matrícula</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/documentos/declaracao/conclusao`)}>
                  <td>Imprimir Declaração de Conclusão de Etapa de Ensino</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/documentos/declaracao/conclusao/serie`)}>
                  <td>Imprimir Declaração de Conclusão de Série</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/documentos/declaracao/frequencia/mensal`)}>
                  <td>Imprimir Declaração de Frequência Mensal</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/documentos/declaracao/transferencia`)}>
                  <td>Imprimir Declaração de Transferência</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/documentos/boletim/aluno`)}>
                  <td>Imprimir Boletim de Aluno</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/documentos/ficha/matricula`)}>
                  <td>Imprimir Ficha de Matrícula</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/documentos/ficha/individual`)}>
                  <td>Imprimir Ficha Individual de Aluno</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/documentos/fichas/individuais/turma`)}>
                  <td>Imprimir Fichas Individuais de Alunos por Turma</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/documentos/declaracao/vagas`)}>
                  <td>Imprimir Declaração de Vagas em Escola</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/documentos/declaracao/geral`)}>
                  <td>Imprimir Declaração Geral</td>
                </tr>
              </tbody>
            </Table>
            </PerfectScrollbar>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
}

export default Documentos;
